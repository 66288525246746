import { shouldShowPriceDrop } from '@/components/srp/search-list/domain'
import { getListingBadgeLabel } from '@/domain/listings/getListingBadgeLabel'
import { BADGE_TYPE_GA_VALUE } from '@/lib/ga/constants/ga'
import { type Listing } from '@/types/search'

/**
 * Gets all the banner types including badges applicable on the listing
 * @param {Listing} item The listing to get the badge values for.
 * @returns
 */
export const getListingFlags = (item: Listing): string[] => {
  const bannerTypesForListing: string[] = []
  const { categoryId, price, flags } = item

  const badgeValue = getListingBadgeLabel({
    categoryId,
    categorySpecificBadge: !!flags.categorySpecificBadge,
  })

  const originalAmount = (price && 'originalAmount' in price && price.originalAmount) || undefined

  const hasPriceDrop = shouldShowPriceDrop(!!item.flags.priceDrop, originalAmount)

  if (hasPriceDrop) {
    bannerTypesForListing.push(BADGE_TYPE_GA_VALUE['hasPriceDrop'])
  }

  if (flags.highlight) {
    bannerTypesForListing.push(BADGE_TYPE_GA_VALUE['highlight'])
  }

  if (flags.showcase) {
    bannerTypesForListing.push(BADGE_TYPE_GA_VALUE['showcase'])
  }

  if ('virtualTour' in flags && flags.virtualTour) {
    bannerTypesForListing.push(BADGE_TYPE_GA_VALUE['virtualTour'])
  }

  if (badgeValue) {
    bannerTypesForListing.push(BADGE_TYPE_GA_VALUE[badgeValue])
  }

  return bannerTypesForListing
}
