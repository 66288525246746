import { type ListingFragment } from '@kijiji/generated/graphql-types'
import { JSON_LD_SCHEMA } from '@kijiji/seo/constants'
import { z } from 'zod'

import { priceFromCents } from '@/utils/kijiji-common/money'
import { getAbsoluteUrl } from '@/utils/url'

const ProductCarouselSeoSchema = z.object({
  name: z.string(),
  url: z.string(),
  image: z.string(),
  price: z.number().optional(),
  description: z.string().optional(),
})

export type ProductSeoProps = z.infer<typeof ProductCarouselSeoSchema>

/**
 * Transforms an array of listings to an array of ProductSeoProps.
 *
 * @param listings - Array of Listing objects.
 * @returns Array of ProductSeoProps.
 */
export const transformListingsToProductSeo = (listings: ListingFragment[]): ProductSeoProps[] => {
  return listings.map((listing) => ({
    name: listing.title,
    url: listing.url,
    image: listing.imageUrls[0] ?? getAbsoluteUrl('/next-assets/images/fb_desktop.jpg'),
    price: listing.price && 'amount' in listing.price ? listing.price.amount : undefined,
    description: listing.description || undefined,
  }))
}

/**
 * Converts an array of ProductSeoProps to SEO-compatible JSON-LD format.
 *
 * @param products - Array of ProductSeoProps.
 * @returns Array of JSON-LD product objects.
 */
export const productCarouselJsonLd = (products: ProductSeoProps[]): object[] => {
  const itemListElement = products.map((product, index) => {
    const structuredMarkup = {
      '@context': JSON_LD_SCHEMA,
      type: 'Product',
      name: product.name,
      description: product.description,
      image: product.image,
      url: product.url,
      offers: {
        '@type': 'Offer',
        price: product.price
          ? `${priceFromCents(product.price).dollars}.${priceFromCents(product.price).cents}`
          : undefined,
        priceCurrency: 'CAD',
        availability: 'https://schema.org/InStock',
      },
    }

    return {
      '@type': 'ListItem',
      position: index + 1,
      item: structuredMarkup,
    }
  })

  return itemListElement
}
