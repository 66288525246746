import { type PropsWithChildren, type SyntheticEvent } from 'react'

import { formatTreeFacets } from '@/domain/filters'
import { useLocale } from '@/hooks/useLocale'

import { AttributeTreeButton } from './styled'

export type AttributeTreeItemProps = PropsWithChildren<{
  /**
   * Defines if list item is selected
   */
  isSelected: boolean
  /**
   * Attribute leaf label
   */
  leafLabel: string
  /**
   * Attribute leaf link
   */
  leafUrl?: string | null
  /**
   * Attribute leaf value
   */
  leafValue: string
  /**
   * Fallback onClick in case there is no leafLink
   */
  onClick: (e: SyntheticEvent<HTMLAnchorElement | HTMLButtonElement>) => void
  /**
   * Total search results for attribute (facets)
   */
  totalResults?: number | null

  /**
   * The level of the attribute in its tree.
   *
   * eg. Category L0-L3
   */
  level: number
}>

/** Each list item containing either Link or Button inside of an attribute tree */
export const AttributeTreeItem = ({
  children,
  isSelected,
  leafLabel,
  leafUrl,
  leafValue,
  onClick,
  totalResults,
  level,
}: AttributeTreeItemProps) => {
  const { routeLocale } = useLocale()

  const formattedLabel = `${leafLabel} ${formatTreeFacets(totalResults, routeLocale)}`

  const leafProps = {
    $selected: isSelected,
    level,
    onClick,
  }

  return (
    <li key={leafValue}>
      {/* It should display a link if the value is returned. Or we request the link on user click */}
      {leafUrl ? (
        <AttributeTreeButton
          as="a"
          href={leafUrl}
          aria-current={isSelected ? 'page' : undefined}
          {...leafProps}
        >
          {formattedLabel}
        </AttributeTreeButton>
      ) : (
        <AttributeTreeButton aria-pressed={isSelected} {...leafProps}>
          {formattedLabel}
        </AttributeTreeButton>
      )}

      {children}
    </li>
  )
}
