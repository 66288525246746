import { getAdSourceForPromotionListing } from '@/components/srp/search-list/domain'
import { getSearchTypeWithKeywords } from '@/domain/search/getSearchTypeWithKeywords'
import { GA_AD_TYPE_VALUE, isKeyOfGAAdType } from '@/lib/ga/constants/ga'
import { type ListingsImpressions, type SearchPageDataLayer } from '@/lib/ga/hooks/useSrpTracking'
import { type Listing } from '@/types/search'
import { convertToLowerCase, removeSpaces, replaceAmpersands } from '@/utils/string'

import { getListingFlags } from './getListingFlags'

const formatCategoryName = (name?: string): string => {
  if (!name) return ''

  return removeSpaces(replaceAmpersands(convertToLowerCase([name])))[0]
}

/**
 * Prepare the GA-tracking listing impressions object to be pushed to dataLayer
 */
export const getDataLayerListingsImpressions = ({
  dataLayerCategory,
  isSeeAllTopAd,
  keywords,
  listings,
}: {
  categoryId?: number
  dataLayerCategory: SearchPageDataLayer['c']
  isSeeAllTopAd?: boolean
  keywords?: string
  listings: Listing[]
}) => {
  const dataLayerEcommerce: ListingsImpressions[] = listings.map((listing, index) => {
    const { adSource, id, price, title } = listing

    const flagValues = getListingFlags(listing)
    const itemBadge = flagValues.length ? { item_badge: flagValues } : {}

    const adSourceFromPromotionListing = getAdSourceForPromotionListing(
      listing.flags,
      adSource,
      isSeeAllTopAd
    )

    const listingAdSource = adSourceFromPromotionListing || adSource

    const promotionName = isKeyOfGAAdType(listingAdSource)
      ? GA_AD_TYPE_VALUE[listingAdSource]
      : GA_AD_TYPE_VALUE.ORGANIC

    const ecommerceItems: NonNullable<ListingsImpressions[]>[0] = {
      index,
      item_category: formatCategoryName(dataLayerCategory?.c?.n),
      item_id: id,
      item_list_name: getSearchTypeWithKeywords(keywords),
      item_name: title,
      price: price && 'amount' in price ? price.amount : undefined,
      promotion_name: promotionName,
      quantity: 1,
      ...itemBadge,
    }

    if (dataLayerCategory?.l1)
      ecommerceItems.item_category1 = formatCategoryName(dataLayerCategory.l1.n)
    if (dataLayerCategory?.l2)
      ecommerceItems.item_category2 = formatCategoryName(dataLayerCategory.l2.n)
    if (dataLayerCategory?.l3)
      ecommerceItems.item_category3 = formatCategoryName(dataLayerCategory.l3.n)

    return ecommerceItems
  })

  return dataLayerEcommerce
}
