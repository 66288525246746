import { LOW_INVENTORY_LIMIT } from '@/constants/search'

/**
 * Defines if a search is considered "low inventory"
 * */
export const isLowInventorySearch = (totalListings: number): boolean => {
  /** If it has less than a defined number of listings, but more than 0 */
  return totalListings > 0 && totalListings <= LOW_INVENTORY_LIMIT
}

/**
 * Defines if it should display listings for an extended location outside of the one chosen by the user
 */
export const hasExtendedRadiusListings = (
  extendedListingsLength: number,
  extendedRadius: number
): boolean => {
  return extendedListingsLength > 0 && !!extendedRadius
}

/**
 * Defines if a search is considered "zero inventory"
 * */
export const isZeroInventorySearch = (totalListings: number): boolean => {
  return totalListings === 0
}

/**
 * Returns the extended radius area based on the current user radius
 * This is used to expand the user's search when there are low number of results
 */
export const getExtendedRadius = (radius?: number): number => {
  return radius ? Math.floor(radius * 0.5) : 0
}
