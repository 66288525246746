import {
  type LocationArea,
  type SearchUrlPopularRelatedInput,
  useGetPopularRelatedKeywordsQuery,
} from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { BodyText } from '@/ui/atoms/body-text'
import { Divider } from '@/ui/atoms/divider'
import { Flex } from '@/ui/atoms/flex'
import { RoundedChip } from '@/ui/atoms/rounded-chip'
import { SkeletonLoading } from '@/ui/atoms/skeleton/styled'
import { sendToLogger } from '@/utils/sendToLogger'
import { capitalizeFirstWord } from '@/utils/string'
import { appendRadialLocationToUrl } from '@/utils/url'

import { PopularRelatedKeywordsList } from './styled'

export type PopularRelatedKeywordsProps = SearchUrlPopularRelatedInput & {
  /**
   * The optional location area to be appended to the url
   */
  locationArea?: LocationArea | null
}

export const PopularRelatedKeywords = ({
  locationArea,
  ...searchUrlPopularInput
}: PopularRelatedKeywordsProps) => {
  const { data, loading, called } = useGetPopularRelatedKeywordsQuery({
    variables: { searchUrlPopularInput },
  })

  const skipping = !called && !data

  const { spacing, colors } = useTheme()

  const { t } = useTranslation('srp')

  const popularRelatedKeywords = data?.searchUrlsPopular || []

  // If we are not loading results and we have no keywords, display nothing
  if (called && !loading && !popularRelatedKeywords.length) return null

  /**
   * The type depends on the current search, if the page is a BRP or an SRP
   * BRP - Browse results page - Search done with no keyword
   * SRP - Search results page - Search done with a keyword
   * */
  const title = searchUrlPopularInput.keywords
    ? t('related_keywords.related')
    : t('related_keywords.popular')

  const handleClick = (keyword: string) => {
    // Should track click on the keywords
    trackEvent({ action: GA_EVENT.RelatedKeywordClick, label: `related_keyword=${keyword}` })
  }

  return (
    <>
      <Flex
        data-testid={`${title.toLowerCase()}-keywords-list`}
        gap={spacing.defaultSmall}
        alignItems="center"
      >
        {loading || skipping ? (
          <SkeletonLoading width="50rem" height="1.6rem" bottom="0rem" />
        ) : (
          <>
            <BodyText id="srp-popular-related-list-title" color={colors.grey.primary} size="xSmall">
              {title}:
            </BodyText>
            <PopularRelatedKeywordsList aria-labelledby="srp-popular-related-list-title">
              {popularRelatedKeywords.map((item, index) => {
                let href = item.url

                if (!href) {
                  sendToLogger(`${title} keyword item with no url - ${item}`, {
                    fingerprint: ['PopularRelatedKeywords'],
                  })
                  return null
                }

                href = appendRadialLocationToUrl(href, locationArea)

                return (
                  // using ListItem instead of li causes a hydration error
                  <li key={`${title}-${index}`}>
                    <RoundedChip>
                      <LinkCustom
                        href={href}
                        color={colors.purple.primary}
                        onClick={() => handleClick(item.label)}
                        size="small"
                        variant="secondary"
                        noStyle
                      >
                        {capitalizeFirstWord(item.label)}
                      </LinkCustom>
                    </RoundedChip>
                  </li>
                )
              })}
            </PopularRelatedKeywordsList>
          </>
        )}
      </Flex>

      <Divider bottom={spacing.defaultSmall} top={spacing.defaultSmall} />
    </>
  )
}
